@import url("https://fonts.googleapis.com/css2?family=Boogaloo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hi+Melody&display=swap");
@font-face {
  font-family: "Soulmeh";
  src: url("assets/fonts/Soulmeh.otf");
}

// Reset css
* {
  padding: 0;
  margin: 0;
}
html,
body {
  font-family: "Boogaloo", sans-serif;
  font-size: 16px;
  background: #000;
}
ol,
ul {
  list-style: none;
}
img {
  vertical-align: top;
  width: 100%;
  border: none;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
input {
  border: none;
}
a {
  cursor: pointer;
}

.App {
  overflow: hidden;
  .container {
    margin: 0 auto;
    overflow: hidden;
    &.wrapper {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 24px;
    }
  }
  .page-main {
    .section-intro {
      background-image: url(assets/images/image-bg-1.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 48px 24px 78px;
        @media only screen and (max-width: 480px) {
          padding: 32px 24px 68px;
        }
        .page-title {
          font-family: Soulmeh;
          font-size: 128px;
          font-weight: 400;
          line-height: 160px;
          text-align: center;
          text-transform: uppercase;
          @media only screen and (max-width: 688px) {
            font-size: 80px;
            line-height: 100px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 64px;
            line-height: 80px;
          }
        }
        .img-intro {
          margin-top: 23px;
          width: 625px;
          border: 3px solid #000;
          border-radius: 32px;
          overflow: hidden;
          @media only screen and (max-width: 688px) {
            width: 425px;
          }
          @media only screen and (max-width: 480px) {
            width: auto;
          }
        }
      }
      .socials {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 17px;
        background: linear-gradient(180deg, #747575 0%, #d8d6d7 100%);
        padding: 11px 0;
        min-width: 1173px;
        border: 3px solid #000;
        border-radius: 20px;
        margin-top: 46px;
        position: absolute;
        bottom: -40px;
        z-index: 99;
        transform: translateX(-50%);
        left: 50%;
        @media only screen and (max-width: 1280px) {
          min-width: 768px;
        }
        @media only screen and (max-width: 867px) {
          min-width: 568px;
        }
        @media only screen and (max-width: 688px) {
          min-width: auto;
          padding: 11px 24px;
        }
        li {
          a {
            img {
              width: 56px;
              @media only screen and (max-width: 480px) {
                width: 40px;
              }
            }
          }
        }
      }
    }
    .section-about {
      background-image: url(assets/images/image-bg-2.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding: 162px 0;
      position: relative;
      z-index: 98;
      @media only screen and (max-width: 688px) {
        padding: 64px 0;
      }
      @media only screen and (max-width: 480px) {
        padding: 64px 0 48px;
      }
      .container {
        display: flex;
        gap: 70px;
        justify-content: center;
        @media only screen and (max-width: 1120px) {
          flex-wrap: wrap;
        }
        @media only screen and (max-width: 688px) {
          gap: 32px;
        }
        .left {
          width: 357px;
          flex-shrink: 0;
          @media only screen and (max-width: 480px) {
            flex-shrink: unset;
            width: auto;
          }
        }
        .right {
          max-width: 828px;
          @media only screen and (max-width: 1120px) {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .title {
            font-size: 96px;
            font-weight: 400;
            line-height: 114.14px;
            text-align: left;
            text-transform: uppercase;
          }
          .content {
            margin-top: 37px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            font-family: Hi Melody;
            font-size: 36px;
            font-weight: 400;
            line-height: 45px;
            text-align: left;
          }
          @media only screen and (max-width: 1120px) {
            .title,
            .content {
              text-align: center;
            }
          }
          @media only screen and (max-width: 688px) {
            .title {
              font-size: 64px;
              line-height: 80px;
            }
            .content {
              margin-top: 18px;
              font-size: 28px;
              line-height: 36px;
            }
          }
          @media only screen and (max-width: 540px) {
            .content {
              margin-top: 18px;
              font-size: 18px;
              line-height: 24px;
            }
          }
          @media only screen and (max-width: 480px) {
            .title {
              font-size: 48px;
              line-height: 64px;
            }
          }
        }
      }
    }
    .section-tokenomics {
      background-image: url(assets/images/image-bg-1.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding: 64px 0 77px;
      @media only screen and (max-width: 786px) {
        padding: 48px 0 64px;
      }
      @media only screen and (max-width: 480px) {
        padding: 32px 0 48px;
      }
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 63px;
        @media only screen and (max-width: 786px) {
          gap: 32px;
        }
        @media only screen and (max-width: 480px) {
          gap: 18px;
        }
        .title {
          font-size: 96px;
          font-weight: 400;
          line-height: 114.14px;
          text-align: center;
          text-transform: uppercase;
          @media only screen and (max-width: 786px) {
            font-size: 64px;
            line-height: 84px;
          }
          @media only screen and (max-width: 567px) {
            font-size: 48px;
            line-height: 64px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 32px;
          }
        }
        .content {
          display: flex;
          gap: 64px;
          @media only screen and (max-width: 1180px) {
            gap: 32px;
            flex-wrap: wrap;
            justify-content: center;
          }
          .left {
            display: flex;
            flex-direction: column;
            gap: 32px;
            @media only screen and (max-width: 480px) {
              gap: 18px;
            }
            .box-content {
              background: linear-gradient(360deg, #ffffff 0%, #999999 100%);
              border: 3px solid #000;
              border-radius: 20px;
              display: flex;
              align-items: center;
              gap: 24px;
              padding: 32px;
              @media only screen and (max-width: 480px) {
                padding: 24px;
              }
              .icon-box {
                width: 129px;
                @media only screen and (max-width: 480px) {
                  width: 80px;
                }
              }
              .des {
                .title {
                  font-size: 36px;
                  font-weight: 400;
                  line-height: 42.8px;
                  text-align: left;
                  text-transform: uppercase;
                  @media only screen and (max-width: 480px) {
                    font-size: 28px;
                  }
                }
                .value {
                  font-size: 72px;
                  font-weight: 400;
                  line-height: 85.61px;
                  text-align: left;
                  text-transform: uppercase;
                  @media only screen and (max-width: 567px) {
                    font-size: 48px;
                    line-height: 64px;
                  }
                  @media only screen and (max-width: 480px) {
                    font-size: 32px;
                    line-height: 48px;
                  }
                }
              }
            }
          }
          .right {
            width: 551px;
            @media only screen and (max-width: 567px) {
              width: auto;
            }
          }
        }
      }
    }
    .section-h2b {
      background-image: url(assets/images/image-bg-2.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 73px;
        position: relative;
        padding: 64px 24px 133px;
        @media only screen and (max-width: 786px) {
          padding: 48px 24px 80px;
          gap: 48px;
        }
        @media only screen and (max-width: 480px) {
          gap: 24px;
        }
        .title {
          font-size: 96px;
          font-weight: 400;
          line-height: 114.14px;
          text-align: center;
          text-transform: uppercase;
          @media only screen and (max-width: 786px) {
            font-size: 64px;
            line-height: 84px;
          }
          @media only screen and (max-width: 567px) {
            font-size: 48px;
            line-height: 64px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 32px;
          }
        }
        .content {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 32px;
          @media only screen and (max-width: 1180px) {
            grid-template-columns: 1fr;
            padding-bottom: 100px;
          }
          @media only screen and (max-width: 480px) {
            gap: 12px;
          }
          .box-content {
            padding: 32px;
            background: linear-gradient(360deg, #ffffff 0%, #999999 100%);
            border: 3px solid #000;
            border-radius: 20px;
            text-transform: uppercase;
            font-size: 36px;
            font-weight: 400;
            line-height: 42.8px;
            text-align: center;
            max-width: 550px;
            @media only screen and (max-width: 586px) {
              font-size: 28px;
              line-height: 32px;
            }
            @media only screen and (max-width: 480px) {
              font-size: 18px;
              line-height: 20px;
            }
          }
        }
        .img-bottom {
          position: absolute;
          width: 291px;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          @media only screen and (max-width: 786px) {
            width: 200px;
          }
        }
      }
    }
  }
  .page-footer {
    .container {
      .copyright {
        text-align: center;
        padding: 13px 0;
        font-size: 20px;
        font-weight: 400;
        line-height: 23.78px;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
      }
    }
  }
}
